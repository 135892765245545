<template>
  <div class="card-main">
    <div class="list-item">
      <span class="name">运营商</span>
      <div class="content">
        <template v-if="userData.operator =='unicom'">
          <svg-icon icon-class="icon_unicom"></svg-icon>
        </template>
        <template v-else-if="userData.operator == 'mobile'">
          <svg-icon icon-class="icon_mobile"></svg-icon>
        </template>
        <template v-else-if="userData.operator == 'telecom'">
          <svg-icon icon-class="icon_telecom"></svg-icon>
        </template>
      </div>
    </div>
    <div class="list-item">
      <span class="name">流量卡号</span>
      <div class="content"><p :class="userData.activation ? '' : 'no-text'">{{ userData.activation ? '已激活' : '未激活'}}</p></div>
    </div>
    <div class="list-item">
      <span class="name">实名状态</span>
      <div class="content">
        <template v-if="userData.isreal">已实名</template>
        <template v-else>
          <p class="no-text">未实名</p>
          <button type="button" class="btn-rz" @click="$router.push('/certification')">去实名</button>
        </template>
      </div>
    </div>
     <div class="list-item">
      <span class="name">剩余流量</span>
      <div class="content"><p>{{ userData.remainder }} GB</p></div>
    </div>
     <div class="list-item">
      <span class="name">有效期至</span>
      <div class="content">{{ userData.effectiveDate }}</div>
    </div>
    <div class="btn-con">
      <button type="button" class="btn">流量充值</button>
      <button type="button" class="btn btn-border">智能诊断</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'user-card',
  data () {
    return {
      // operator: 'unicom',
      // remainder: 50
    }
  },
  created () {

  },
  mounted () {
    // document.title = this.$route.meta.title
  },
  computed: {
    ...mapState({
      userData: state => state.user.userData
    })
  }
}
</script>

<style lang="scss" scoped>
  .card-main{
    padding:1rem 1.25rem 0;
    .list-item{
      height: 2.75rem;
      display: flex;
      align-items: center;
      font-size: 0.75rem;
      .name{
        width: 3.735rem;
        padding-left: 0.25rem;
        box-sizing: border-box;
        color: #999;
        margin-right:1.25rem;
      }
      .content{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        .no-text {
          color:#FF6B00;
        }
      }
      .svg-icon{
        width: 1.875rem;
        height: 1.875rem;
      }
      .btn-rz{
        height: 1.125rem;
        line-height: 1.125rem;
        border-radius:0.5625rem;
        white-space: nowrap;
        padding: 0 0.4357rem;
        background-color:  #0182F9;
        color:#fff;
      }
    }
    .btn-con{
      margin-top: 8rem;
    }
    .btn-border{
      background: #fff;
      border: 1px solid #0182F9;
      margin-top:0.625rem;
      color:#0182F9;
    }
  }
</style>
